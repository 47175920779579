import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../../stores/study-map.store';
import { EVENTS as STUDY_EXPERT_LAYER_STORE_EVENTS } from '../../stores/study-expert-layer.store';
import { EVENTS as FIELD_RECORD_LIST_GEOM_STORE_EVENTS } from '../../../field-record/stores/field-record-list-map.store';
import { EVENTS as FIELD_SURVEY_LIST_GEOM_STORE_EVENTS } from '../../../field-survey/stores/field-survey-list-map.store';
import { EVENTS as LOCATION_EVENTS } from '../../../map/services/geolocation.service.js';
import { EVENTS as KML_SERVICE_EVENTS } from '../../services/study-kml.service';
import { LAYERS_CONFIG } from '../../../map/configuration';
import StudyConfiguration from '../../configuration';
import { APPLICATION_KEY } from '../../model.js';

require('./study-map.scss');

export default StudyMapDirective;

// @ngInject
function StudyMapDirective() {
  return {
    restrict: 'E',
    scope: { studyId: '@' },
    controller: StudyMapController,
    controllerAs: 'studyMapCtrl',
    bindToController: true,
    template: require('./study-map.html'),
    replace: true,
  };
}

// @ngInject
function StudyMapController(
  $scope,
  $compile,
  $state,
  $rootScope,
  StudyMapStore,
  StudyMapService,
  StudyDetailsService,
  HeaderService,
  StudyKmlService,
  FieldRecordListMapStore,
  FieldRecordListMapService,
  FieldSurveyListMapStore,
  FieldSurveyListMapService,
  StudyExpertLayerService,
  StudyExpertLayerStore,
  Geolocation,
  LxNotificationService
) {
  var studyMapCtrl = this;

  studyMapCtrl.onLayerChanged = StudyMapService.saveCurrentLayer;
  studyMapCtrl.onLocalLayerChanged = onLocalLayerChanged;
  studyMapCtrl.onSubmitKml = onSubmitKml;
  studyMapCtrl.canEditStudy = canEditStudy;
  studyMapCtrl.isLoaded = isLoaded;
  studyMapCtrl.isProcessing = isProcessing;
  studyMapCtrl.canCreateSubElements = canCreateSubElements;
  studyMapCtrl.onMove = onMove;
  studyMapCtrl.tracking = {};
  studyMapCtrl.trackingState = null;
  studyMapCtrl.trackingCtrl = null;
  studyMapCtrl.onMapReady = onMapReady;

  studyMapCtrl.uploadingKml = false;
  studyMapCtrl.noGeoms = true;

  $scope.$on(STORE_EVENTS.STUDY_MAP_STATE_CHANGED, onStateChanged);
  $scope.$on(FIELD_RECORD_LIST_GEOM_STORE_EVENTS.FIELD_RECORD_LIST_MAP_STATE_CHANGED, onStateChanged);
  $scope.$on(FIELD_SURVEY_LIST_GEOM_STORE_EVENTS.FIELD_SURVEY_LIST_MAP_STATE_CHANGED, onStateChanged);
  $scope.$on(STUDY_EXPERT_LAYER_STORE_EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED, onStateChanged);

  $scope.$on(KML_SERVICE_EVENTS.STUDY_KML_UPDATING, onUploadingKml);
  $scope.$on(KML_SERVICE_EVENTS.STUDY_KML_UPDATE_SUCCESS, onUploadKmlSuccess);
  $scope.$on(KML_SERVICE_EVENTS.STUDY_KML_UPDATE_ERROR, onUploadKmlError);

  StudyDetailsService.load(studyMapCtrl.studyId);

  reload();

  function onLocalLayerChanged(layerId) {
    StudyMapService.saveLayerVisibility(studyMapCtrl.studyId, layerId);
  }

  function isLoaded() {

    if(!IS_CORDOVA){
      studyMapCtrl.trackingState = 'loaded';
    }
    return (
      studyMapCtrl.state &&
      studyMapCtrl.state.loaded &&
      studyMapCtrl.fieldRecordListGeomState &&
      studyMapCtrl.fieldRecordListGeomState.loaded &&
      studyMapCtrl.fieldSurveyListGeomState &&
      studyMapCtrl.fieldSurveyListGeomState.loaded &&
      studyMapCtrl.layersState &&
      studyMapCtrl.layersState.loaded &&
      studyMapCtrl.trackingState === 'loaded'
    );
  }

  function isProcessing() {
    return studyMapCtrl.uploadingKml;
  }

  function canCreateSubElements() {
    return studyMapCtrl.state && studyMapCtrl.state.study && studyMapCtrl.state.study.canCreateSubElements;
  }

  function onStateChanged() {
    studyMapCtrl.state = StudyMapStore.getState();
    studyMapCtrl.fieldRecordListGeomState = FieldRecordListMapStore.getState();
    studyMapCtrl.fieldSurveyListGeomState = FieldSurveyListMapStore.getState();
    studyMapCtrl.layersState = StudyExpertLayerStore.getState();

    if (!isLoaded()) {
      return;
    }

    studyMapCtrl.layers = [];

    if (studyMapCtrl.layersState.loaded && studyMapCtrl.layersState.layers) {
      studyMapCtrl.layers = studyMapCtrl.layers.concat(
        StudyExpertLayerService.buildLayerList(studyMapCtrl.layersState, studyMapCtrl.state.study.id)
      );
    }
    if (studyMapCtrl.state.loaded && studyMapCtrl.state.study.studyBoundary) {
      studyMapCtrl.layers.push({
        name: "Aire d'étude",
        config: LAYERS_CONFIG.STUDY,
        fitBounds: true,
        features: [
          _.defaults(
            {
              properties: { name: studyMapCtrl.state.study.key },
            },
            studyMapCtrl.state.study.studyBoundary
          ),
        ],
      });
      studyMapCtrl.noGeoms = false;
    }
    if (studyMapCtrl.fieldSurveyListGeomState.fieldSurveys.length) {
      studyMapCtrl.layers.push({
        name: 'Relevés',
        config: LAYERS_CONFIG.FIELD_SURVEY,
        fitBounds: true,
        features: _.map(studyMapCtrl.fieldSurveyListGeomState.fieldSurveys, (fieldSurvey) =>
          _.defaults(
            {
              properties: { studyId: studyMapCtrl.studyId, fieldSurveyId: fieldSurvey.id, name: fieldSurvey.name },
            },
            fieldSurvey.geometry
          )
        ),
      });
      studyMapCtrl.layers.push({
        name: 'Étiquettes relevés',
        config: LAYERS_CONFIG.FIELD_SURVEY_POPUP,
      });
      studyMapCtrl.noGeoms = false;
    }

    if (studyMapCtrl.tracking.surveys && studyMapCtrl.tracking.surveys.length) {
      studyMapCtrl.trackingCtrl = new LAYERS_CONFIG.TRACKING(
        $rootScope,
        $compile,
        Geolocation,
        LxNotificationService,
        studyMapCtrl.studyId
      );
      studyMapCtrl.layers.push({
        name: 'Traces',
        config: studyMapCtrl.trackingCtrl.getConf(),
        fitBounds: true,
        isTrackingLayer: true,
        features: studyMapCtrl.tracking.surveys.map((trackingSurvey, i) => {
          return {
            properties: {
              name: `Trace ${trackingSurvey.name}`,
              index: i,
              trackingSurvey,
            },
            type: 'LineString',
            coordinates: trackingSurvey.points.map((point) => {
              return [point.longitude, point.latitude];
            }),
          };
        }),
      });
      studyMapCtrl.layers.push({
        name: 'Étiquettes des traces',
        config: studyMapCtrl.trackingCtrl.getPopupCtrl(),
      });
      studyMapCtrl.noGeoms = false;
    }

    if (studyMapCtrl.fieldRecordListGeomState.fieldRecords.length) {

      studyMapCtrl.layers.push({
        name: 'Observations',
        config: LAYERS_CONFIG.TX_POSITION,
        fitBounds: true,
        features: _.map(studyMapCtrl.fieldRecordListGeomState.fieldRecords, (fieldRecord) =>
          _.defaults(
            {
              properties: {
                studyId: studyMapCtrl.studyId,
                fieldRecordId: fieldRecord.id,
                name: fieldRecord.txHeadcount
                  ? `${fieldRecord.txName} : ${fieldRecord.txHeadcount}`
                  : fieldRecord.txName,
              },
            },
            fieldRecord.txPosition
          )
        ),
      });
      studyMapCtrl.layers.push({
        name: 'Étiquettes observations',
        config: LAYERS_CONFIG.TX_POPUP,
      });
      studyMapCtrl.noGeoms = false;
    }

    if (studyMapCtrl.state.study) {
      HeaderService.updateTitle([
        {
          label: studyMapCtrl.state.study.key,
          link: {
            sref: 'study-details',
            params: { studyId: studyMapCtrl.studyId },
          },
        },
        'carte',
      ]);
    }
  }

  function reload() {
    StudyMapService.load(studyMapCtrl.studyId);
    FieldRecordListMapService.loadForStudy(studyMapCtrl.studyId);
    FieldSurveyListMapService.loadForStudy(studyMapCtrl.studyId);
    StudyExpertLayerService.load(studyMapCtrl.studyId);
    studyMapCtrl.initialPosition = StudyMapService.getCurrentPositionAsGeoJson(studyMapCtrl.studyId);

    if(IS_CORDOVA){
      studyMapCtrl.trackingState = 'loading';
      Geolocation.getTrackingForStudy(studyMapCtrl.studyId).then((tracking) => {
        studyMapCtrl.tracking = tracking;
        studyMapCtrl.trackingState = 'loaded';
        onStateChanged();
      });
    }

  }

  function onUploadingKml() {
    studyMapCtrl.uploadingKml = true;
  }
  function onUploadKmlSuccess() {
    studyMapCtrl.uploadingKml = false;
    $state.go('study-map', { studyId: studyMapCtrl.studyId }, { reload: true });
  }
  function onUploadKmlError() {
    studyMapCtrl.uploadingKml = false;
  }

  function onSubmitKml(input) {
    if (input.files.length <= 0) {
      return;
    }
    StudyKmlService.onUpdateKml(studyMapCtrl.studyId, input.files[0]);
  }

  function canEditStudy() {
    // On ne peut pas modifier une étude si elle est verrouillée par un utilisateur
    return (
      studyMapCtrl.state.study.canEdit &&
      studyMapCtrl.state.study.locksInfo.length === 0 &&
      studyMapCtrl.state.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG
    );
  }

  function geoJsonFromLatlng(lat, lng) {
    return { type: 'Point', coordinates: [lng, lat] };
  }

  function onMove(center, zoom) {
    StudyMapService.saveCurrentPosition(studyMapCtrl.studyId, center.lat, center.lng, zoom);
  }

  function onMapReady(mapApi) {
    if (studyMapCtrl.trackingCtrl) {
      studyMapCtrl.trackingCtrl.onMapReady(mapApi);
    }
  }
}
