import _ from 'lodash';

import { EVENTS as BIOTOPE_REF_MAP_STORE_EVENTS } from '../../stores/biotope-ref-map.store';
import angular from 'angular';

require('./select-biotope-ref-st.scss');

export default SelectBiotopeRefStDirective;

// @ngInject
function SelectBiotopeRefStDirective() {
  return {
    restrict: 'E',
    scope: {
      initialValueIdProvider: '&initialValueId',
      initialValueKeyProvider: '&initialValueKey',
      placeholder: '@',
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit',
      requiredProvider: '&required',
      forceClearProvider: '&forceClear',
      refTableName: '@'
    },
    bindToController: true,
    controller: SelectBiotopeRefStController,
    controllerAs: 'selectBiotopeRefStCtrl',
    template: require('./select-biotope-ref-st.html'),
    replace: true
  };
}

// @ngInject
function SelectBiotopeRefStController($scope, BiotopeRefMapStore, BiotopeRefMapService,LxNotificationService) {
  var selectBiotopeRefStCtrl = this;

  selectBiotopeRefStCtrl.onChange = onChange;
  selectBiotopeRefStCtrl.currentValues = [];
  selectBiotopeRefStCtrl.deletedValues = [];
  selectBiotopeRefStCtrl.isSameRefParam = false;
  selectBiotopeRefStCtrl.maxLength = true;

  $scope.$on(BIOTOPE_REF_MAP_STORE_EVENTS.BIOTOPE_REF_MAP_RESULTS_STATE_CHANGED, reloadResults);
  selectBiotopeRefStCtrl.availableValues = {};
  selectBiotopeRefStCtrl.availableKeys = [];
  selectBiotopeRefStCtrl.state = {};
  BiotopeRefMapService.load(selectBiotopeRefStCtrl.refTableName);

  $scope.$watch(selectBiotopeRefStCtrl.forceClearProvider, (forceClear) => {
      selectBiotopeRefStCtrl.forceClear = forceClear;
  });
  $scope.$watch(selectBiotopeRefStCtrl.canEditProvider, (canEdit) => {
    selectBiotopeRefStCtrl.canEdit = canEdit;
  });
  $scope.$watch(selectBiotopeRefStCtrl.requiredProvider, (required) => {
    selectBiotopeRefStCtrl.required = required;
  });
  $scope.$watch(selectBiotopeRefStCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId && !selectBiotopeRefStCtrl.forceClear) {
      return;
    }

    selectBiotopeRefStCtrl.initialValueId = initialValueId;
    updateInitialValue();
  });
  $scope.$watch(selectBiotopeRefStCtrl.initialValueKeyProvider, (initialValueKey) => {
    if ((!initialValueKey || initialValueKey.length===0) && !selectBiotopeRefStCtrl.forceClear) {
      return;
    }

    selectBiotopeRefStCtrl.initialValueKey = initialValueKey;

    updateInitialValue();
  });

  function reloadResults(event, tableChanged) {
    if (tableChanged !== selectBiotopeRefStCtrl.refTableName) {
      return;
    }

    selectBiotopeRefStCtrl.state = BiotopeRefMapStore.getState(selectBiotopeRefStCtrl.refTableName);
;
    if (!selectBiotopeRefStCtrl.state.results) {
      return;
    }

 
    selectBiotopeRefStCtrl.availableValues =formatResults(_.reject(selectBiotopeRefStCtrl.state.results, 'deleted'));
    selectBiotopeRefStCtrl.availableKeys = Object.keys(selectBiotopeRefStCtrl.availableValues);
    updateInitialValue();
  }

  function formatResults(values) {
    if (!_.some(values, 'header')) {
      return values;
    }

    var res = {};
    var header = '-';
    var options = [];

    for (let i = 0; i < values.length; i++) {
      if (!values[i].header) {
        options.push(Object.assign({}, values[i]));
        continue;
      }
      if (options.length > 0) {
        res[header] = options;
      }
      options = [];
      header = values[i].label;

    }
    res[header] = options;
    return res;
  }

  function updateInitialValue() {

    if (!selectBiotopeRefStCtrl.forceClear &&
        (!(selectBiotopeRefStCtrl.initialValueId || selectBiotopeRefStCtrl.initialValueKey) || !selectBiotopeRefStCtrl.state.loaded)) {
      return;
    }
    let initialValues = null;
    if (selectBiotopeRefStCtrl.initialValueId) {
      initialValues =[];
      // On cherche à partir de initialValueId
      angular.forEach(selectBiotopeRefStCtrl.initialValueId,function(i){
        var result = _.find(selectBiotopeRefStCtrl.state.results, {id: i});
        initialValues.push(result)
      })
    }
    if ((!initialValues || initialValues.length===0) && selectBiotopeRefStCtrl.initialValueKey) {
        initialValues =[];
        angular.forEach(selectBiotopeRefStCtrl.initialValueKey,function(i){
         
        var result = _.find(selectBiotopeRefStCtrl.state.results, {key: i});
        initialValues.push(result)
      })
      
    }
    if ((!initialValues || initialValues.length===0) && !selectBiotopeRefStCtrl.forceClear) {
      // On n'a pas trouvé de valeur à initialiser à partir de initialValueKey
      return;
    }

    onChange(initialValues);

    if ((initialValues || initialValues.length != 0 )  && _.find(initialValues,{deleted:true})) {
      selectBiotopeRefStCtrl.deletedValue = _.find(initialValues,{deleted:true});
      return;
    }

    selectBiotopeRefStCtrl.currentValues = initialValues;
    selectBiotopeRefStCtrl.currentValueId = (initialValues && initialValues.length!=0) ? initialValues.map(i => i.key) : [];

  }


  function onChange(newValue) {
    
    selectBiotopeRefStCtrl.maxLength= newValue.length <= selectBiotopeRefStCtrl.availableKeys.length;

    if(!selectBiotopeRefStCtrl.maxLength){
      LxNotificationService.error(selectBiotopeRefStCtrl.availableKeys.length + ' paramètres max');
      return;
    }

    if(newValue.length === selectBiotopeRefStCtrl.availableKeys.length){
        //check if all selected item are in same ref param or not. Must be not the same to be valid.
        selectBiotopeRefStCtrl.isSameRefParam = newValue.every( (val, i, arr) => val.ref === arr[0].ref && arr.length !=1);
        if(selectBiotopeRefStCtrl.isSameRefParam){
          LxNotificationService.error("Veuillez sélectionner un paramètre PAR catérories");
          return;
        }

  }

    var is_equal=newValue == selectBiotopeRefStCtrl.currentValues;


    if(selectBiotopeRefStCtrl.currentValues !=null){
      is_equal = (newValue.length === selectBiotopeRefStCtrl.currentValues.length) && newValue.every((value, index) => value.key === selectBiotopeRefStCtrl.currentValues[index].key);  
    
    }

    
    if(!is_equal){
      selectBiotopeRefStCtrl.currentValueId = newValue.length!=0  ? newValue.map(i => i.key) : [];
      selectBiotopeRefStCtrl.onChangeFn() && selectBiotopeRefStCtrl.onChangeFn()(newValue);

    }

  }
}
