import _ from 'lodash';

const EVENTS = {
  FIELD_RECORD_DETAILS_LOADING: 'shu-field-record.field-record-details-loading',
  FIELD_RECORD_DETAILS_LOAD_SUCCESS: 'shu-field-record.field-record-details-load-success',
  FIELD_RECORD_DETAILS_LOAD_ERROR: 'shu-field-record.field-record-details-load-error',
  FIELD_RECORD_DETAILS_PROCESSING: 'shu-field-record.field-record-details-processing',
  FIELD_RECORD_DETAILS_NEW_PROCESSING: 'shu-field-record.field-record-details-new-processing',
};

export default FieldRecordDetailsService;

// @ngInject
function FieldRecordDetailsService($rootScope, FieldRecordRepository, LxNotificationService, $state) {
  return { load, createFieldRecord, createFieldRecordOnTheFly, updateFieldRecord, deleteFieldRecord };

  function load(fieldRecordId) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOADING);

    FieldRecordRepository.getById(fieldRecordId, true).then(
      (fieldRecord) => {
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, fieldRecord);
        return fieldRecord;
      },
      (error) => {
        LxNotificationService.error(
          "Une erreur est survenue lors de la récupération de l'observation. " + (error.data || '')
        );
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }

  function createFieldRecord(
    studyId,
    fieldRecordForm,
    mapDataForm,
    fieldRecordExtraTableForms,
    fromState,
    fromParams,
    continueCreation,
    formController
  ) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_NEW_PROCESSING);
    let fieldRecordFormWithMap = _.defaults(fieldRecordForm, mapDataForm);

    if(!mapDataForm.txPosition) {
      //LxNotificationService.error("L'observation ne peut être créée car la localisation n'a pas été validée dans l'onglet carte.");
      return Promise.reject("L'observation ne peut être créée car la localisation n'a pas été validée dans l'onglet carte.");
    }

    return FieldRecordRepository.create(studyId, fieldRecordFormWithMap, fieldRecordExtraTableForms).then(
      (fieldRecord) => {
        formController.$setPristine();
        LxNotificationService.success('Observation créée');
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, fieldRecord);
        return fieldRecord;
      },
      (error) => {
        LxNotificationService.error("Une erreur est survenue à la création de l'observation. " + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }

  function createFieldRecordOnTheFly(studyId, fieldRecordForm, mapDataForm, extraTablesForm, formController) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_NEW_PROCESSING);

    let fieldRecordFormWithMap = _.defaults(fieldRecordForm, mapDataForm);

    FieldRecordRepository.create(studyId, fieldRecordFormWithMap, extraTablesForm).then(
      (fieldRecord) => {
        formController.$setPristine();
        LxNotificationService.success('Observation créée');
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, fieldRecord);
      },
      (error) => {
        LxNotificationService.error("Une erreur est survenue à la création de l'observation. " + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }

  function updateFieldRecord(
    fieldRecordId,
    fieldRecordForm,
    mapDataForm,
    mediaForm,
    fieldRecordExtraTableForms,
    formController,
    noEmit
  ) {
    if (!noEmit) {
      $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_PROCESSING);
    }

    let fieldRecordFormWithMap = _.defaults(fieldRecordForm, mapDataForm);

    return FieldRecordRepository.update(fieldRecordId, fieldRecordFormWithMap, mediaForm, fieldRecordExtraTableForms).then(
      (fieldRecord) => {
        formController.$setPristine();
        LxNotificationService.success('Observation mise à jour');
        if (!noEmit) {
          $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, fieldRecord);
        }
        return fieldRecord;
      },
      (error) => {
        LxNotificationService.error("Une erreur est survenue à la mise à jour de l'observation. " + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }

  function deleteFieldRecord(fieldRecordId) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_PROCESSING);

    FieldRecordRepository.deleteFieldRecord(fieldRecordId).then(
      (fieldRecord) => {
        LxNotificationService.success('Observation supprimée');
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, null);
      },
      (error) => {
        LxNotificationService.error("Une erreur est survenue à la suppression de l'observation. " + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR);
        throw error;
      }
    );
  }
}
export { EVENTS };
