import _ from 'lodash';
import Leaflet from 'leaflet';

import { EVENTS as STUDY_GEOM_STORE_EVENTS } from '../../../study/stores/study-map.store';
import { LAYERS_CONFIG } from '../../../map/configuration';
import { geoJsonFromLatlng } from '../../../map/tools/map-util.js';

require('./field-record-details-map-tab.scss');

export default FieldRecordDetailsMapTabDirective;

// @ngInject
function FieldRecordDetailsMapTabDirective() {
  return {
    restrict: 'E',
    scope: {
      txPositionProvider: '&txPosition',
      expertPositionProvider: '&expertPosition',
      expertPositionAccuracyProvider: '&expertPositionAccuracy',
      fieldSurveyStateProvider: '&fieldSurveyState',
      layersStateProvider: '&layersState',
      onLocalLayerChangedFn: '&onLocalLayerChanged',
      canEditFn: '&canEdit',
      onPositionChangeFn: '&onPositionChange',
      onLockedTabChangeFn: '&onLockedTabChange',
      studyId: '@',
      automaticallySetPositionFromGpsProvider: '&automaticallySetPositionFromGps',
      automaticallyStartEditingProvider: '&automaticallyStartEditing',
    },
    bindToController: true,
    controller: FieldRecordDetailsMapTabController,
    controllerAs: 'fieldRecordDetailsMapTabCtrl',
    template: require('./field-record-details-map-tab.html'),
    replace: true,
  };
}

// @ngInject
function FieldRecordDetailsMapTabController(
  $scope,
  Geolocation,
  StudyMapStore,
  StudyMapService,
  LxNotificationService,
  StudyExpertLayerService
) {
  var fieldRecordDetailsMapTabCtrl = this;

  fieldRecordDetailsMapTabCtrl.layers = [];
  fieldRecordDetailsMapTabCtrl.loaded = false;
  fieldRecordDetailsMapTabCtrl.hasStudyGeom = false;
  fieldRecordDetailsMapTabCtrl.geomEditors = [];
  fieldRecordDetailsMapTabCtrl.viewZoom = 16;
  fieldRecordDetailsMapTabCtrl.gpsLoading = false;

  fieldRecordDetailsMapTabCtrl.onLayerChanged = StudyMapService.saveCurrentLayer;
  fieldRecordDetailsMapTabCtrl.canEdit = canEdit;
  fieldRecordDetailsMapTabCtrl.isGeomEditorEnabled = isGeomEditorEnabled;
  fieldRecordDetailsMapTabCtrl.startEditing = startEditing;
  fieldRecordDetailsMapTabCtrl.onValidateEditing = onValidateEditing;
  fieldRecordDetailsMapTabCtrl.onCancelEditing = onCancelEditing;
  fieldRecordDetailsMapTabCtrl.onMove = onMove;
  fieldRecordDetailsMapTabCtrl.setPositionFromGPS = setPositionFromGPS;
  fieldRecordDetailsMapTabCtrl.onLocalLayerChanged = onLocalLayerChanged;
  fieldRecordDetailsMapTabCtrl.startDraw = startDraw;
  fieldRecordDetailsMapTabCtrl.onDrawEdited = onDrawEdited;
  fieldRecordDetailsMapTabCtrl.onDrawDeleted = onDrawDeleted;

  $scope.$watch(fieldRecordDetailsMapTabCtrl.expertPositionProvider, onExpertPositionChanged);
  $scope.$watch(fieldRecordDetailsMapTabCtrl.expertPositionAccuracyProvider, onExpertPositionAccuracyChanged);
  $scope.$watch(fieldRecordDetailsMapTabCtrl.txPositionProvider, onTxPositionChanged);
  $scope.$watch(fieldRecordDetailsMapTabCtrl.fieldSurveyStateProvider, onFieldSurveyStateChanged);
  $scope.$watch(fieldRecordDetailsMapTabCtrl.layersStateProvider, onLayersStateChanged);

  var drawGeom = null;

  var removeAutomaticallySetPositionFromGpsWatcher = $scope.$watch(
    fieldRecordDetailsMapTabCtrl.automaticallySetPositionFromGpsProvider,
    (automaticallySetPositionFromGps) => {
      if (automaticallySetPositionFromGps === undefined) {
        return;
      }

      if (automaticallySetPositionFromGps) {
        setPositionFromGPS();
      }
      removeAutomaticallySetPositionFromGpsWatcher();
    }
  );
  var removeAutomaticallyStartEditingWatcher = $scope.$watch(
    fieldRecordDetailsMapTabCtrl.automaticallyStartEditingProvider,
    (automaticallyStartEditing) => {
      if (automaticallyStartEditing === undefined) {
        return;
      }

      if (automaticallyStartEditing) {
        startEditing();
      }
      removeAutomaticallyStartEditingWatcher();
    }
  );

  $scope.$on(STUDY_GEOM_STORE_EVENTS.STUDY_MAP_STATE_CHANGED, onMapStudyStateChanged);

  //reload();

  fieldRecordDetailsMapTabCtrl.mapCenterMarker = LAYERS_CONFIG.TX_POSITION_DETAIL;

  function isGeomEditorEnabled(editorName) {
    return fieldRecordDetailsMapTabCtrl.geomEditors.indexOf(editorName) > -1;
  }

  function onLocalLayerChanged(layerId) {
    fieldRecordDetailsMapTabCtrl.onLocalLayerChangedFn() &&
      fieldRecordDetailsMapTabCtrl.onLocalLayerChangedFn()(layerId);
  }

  function canEdit() {
    return fieldRecordDetailsMapTabCtrl.canEditFn() && fieldRecordDetailsMapTabCtrl.canEditFn()();
  }

  function onMapStudyStateChanged() {
    fieldRecordDetailsMapTabCtrl.studyGeomState = StudyMapStore.getState();
    updateLayers();
  }

  function onFieldSurveyStateChanged(fieldSurveyState) {
    fieldRecordDetailsMapTabCtrl.fieldSurveyState = fieldSurveyState;
    const protocol = _.get(fieldSurveyState, 'fieldSurvey.surveyType.protocol');
    if (!protocol) {
      fieldRecordDetailsMapTabCtrl.geomEditors = ['point', 'draw'];
    } else {
      var geomType = protocol.fieldSurveyGeometryType;
      if (['POINT', 'ALL_SIMPLE'].indexOf(geomType) > -1) {
        fieldRecordDetailsMapTabCtrl.geomEditors.push('point');
      }
      if (protocol.key != 'PE' && ['LINESTRING', 'ALL_SIMPLE'].indexOf(geomType) > -1) {
        fieldRecordDetailsMapTabCtrl.geomEditors.push('draw');
      }
    }
    if (_.get(protocol, 'key') == 'RI') {
      fieldRecordDetailsMapTabCtrl.initialPosition = StudyMapService.getCurrentPositionAsGeoJson(
        fieldRecordDetailsMapTabCtrl.studyId
      );
    }
    reload();
  }

  function onLayersStateChanged(layersState) {
    fieldRecordDetailsMapTabCtrl.layersState = layersState;
    updateLayers();
  }

  function onExpertPositionChanged(expertPosition) {
    fieldRecordDetailsMapTabCtrl.expertPosition = expertPosition;
    fieldRecordDetailsMapTabCtrl.currentExpertPosition = expertPosition;
    updateLayers();
  }

  function onExpertPositionAccuracyChanged(expertPositionAccuracy) {
    fieldRecordDetailsMapTabCtrl.expertPositionAccuracy = expertPositionAccuracy;
    fieldRecordDetailsMapTabCtrl.currentExpertPositionAccuracy = expertPositionAccuracy;
    updateLayers();
  }

  function onTxPositionChanged(txPosition) {
    fieldRecordDetailsMapTabCtrl.txPosition = txPosition;
    fieldRecordDetailsMapTabCtrl.currentTxPosition = txPosition;
    updateLayers();
  }

  function updateLayers() {
    if (
      !fieldRecordDetailsMapTabCtrl.studyGeomState ||
      !fieldRecordDetailsMapTabCtrl.studyGeomState.loaded ||
      !fieldRecordDetailsMapTabCtrl.layersState ||
      !fieldRecordDetailsMapTabCtrl.layersState.loaded
    ) {
      return;
    }

    fieldRecordDetailsMapTabCtrl.layers = [];

    if (fieldRecordDetailsMapTabCtrl.layersState.loaded && fieldRecordDetailsMapTabCtrl.layersState.layers) {
      fieldRecordDetailsMapTabCtrl.layers = fieldRecordDetailsMapTabCtrl.layers.concat(
        StudyExpertLayerService.buildLayerList(
          fieldRecordDetailsMapTabCtrl.layersState,
          fieldRecordDetailsMapTabCtrl.studyGeomState.study.id
        )
      );
    }
    // On ajoute le layer de l'étude
    if (
      fieldRecordDetailsMapTabCtrl.studyGeomState.study &&
      fieldRecordDetailsMapTabCtrl.studyGeomState.study.studyBoundary
    ) {
      fieldRecordDetailsMapTabCtrl.hasStudyGeom = true;
      fieldRecordDetailsMapTabCtrl.layers.push({
        name: "Aire d'étude",
        config: LAYERS_CONFIG.STUDY,
        fitBounds: !fieldRecordDetailsMapTabCtrl.isEditing || !fieldRecordDetailsMapTabCtrl.currentTxPosition,
        features: [
          _.defaults(
            {
              properties: { name: fieldRecordDetailsMapTabCtrl.studyGeomState.study.key },
            },
            fieldRecordDetailsMapTabCtrl.studyGeomState.study.studyBoundary
          ),
        ],
      });
    }
    // On ajoute le layer du relevé
    if (
      fieldRecordDetailsMapTabCtrl.fieldSurveyState &&
      fieldRecordDetailsMapTabCtrl.fieldSurveyState.fieldSurvey &&
      fieldRecordDetailsMapTabCtrl.fieldSurveyState.fieldSurvey.geometry
    ) {
      fieldRecordDetailsMapTabCtrl.hasStudyGeom = true;
      fieldRecordDetailsMapTabCtrl.layers.push({
        name: 'Relevé',
        config: LAYERS_CONFIG.FIELD_SURVEY,
        fitBounds: !fieldRecordDetailsMapTabCtrl.isEditing || !fieldRecordDetailsMapTabCtrl.currentTxPosition,
        features: [
          _.defaults(
            {
              properties: {
                studyId: fieldRecordDetailsMapTabCtrl.studyId,
                fieldSurveyId: fieldRecordDetailsMapTabCtrl.fieldSurveyState.fieldSurvey.id,
                name: fieldRecordDetailsMapTabCtrl.fieldSurveyState.fieldSurvey.name,
              },
            },
            fieldRecordDetailsMapTabCtrl.fieldSurveyState.fieldSurvey.geometry
          ),
        ],
      });
    }
    // On ajoute le layer expertPosition
    if (fieldRecordDetailsMapTabCtrl.currentExpertPosition) {
      if (fieldRecordDetailsMapTabCtrl.currentExpertPositionAccuracy) {
        var distance = fieldRecordDetailsMapTabCtrl.currentExpertPositionAccuracy.toFixed(0);
        fieldRecordDetailsMapTabCtrl.layers.push({
          name: "Précision de la position de l'expert",
          config: LAYERS_CONFIG.EXPERT_POSITION,
          circle: {
            point: fieldRecordDetailsMapTabCtrl.currentExpertPosition,
            radius: distance,
            style: LAYERS_CONFIG.EXPERT_POSITION.circleStyle,
          },
          fitBounds: false,
        });
      }
      fieldRecordDetailsMapTabCtrl.layers.push({
        name: "Position de l'expert",
        config: LAYERS_CONFIG.EXPERT_POSITION,
        fitBounds: !fieldRecordDetailsMapTabCtrl.isEditing,
        features: [
          _.defaults(
            {
              properties: { name: "Position GPS de l'expert" },
            },
            fieldRecordDetailsMapTabCtrl.currentExpertPosition
          ),
        ],
      });
    }
    // On ajoute le layer txPosition si on n'est pas en mode édition
    if (
      fieldRecordDetailsMapTabCtrl.currentTxPosition &&
      (!fieldRecordDetailsMapTabCtrl.isEditing || fieldRecordDetailsMapTabCtrl.isEditing == 'draw')
    ) {
      fieldRecordDetailsMapTabCtrl.layers.push({
        name: 'Position du taxon',
        config: {
          drawnable: true,
          ...LAYERS_CONFIG.TX_POSITION_DETAIL,
        },
        fitBounds: true,
        features: [
          _.defaults(
            {
              properties: { name: 'Position du taxon' },
            },
            fieldRecordDetailsMapTabCtrl.currentTxPosition
          ),
        ],
      });
    }
    fieldRecordDetailsMapTabCtrl.loaded = true;
  }

  function reload() {
    StudyMapService.load(fieldRecordDetailsMapTabCtrl.studyId);
    // TODO: récupérer la geométrie du relevé
  }

  function onPositionChange(txPosition, expertPosition, expertPositionAccuracy, goToFirstTab) {
    $scope.fieldRecordMapForm.$setDirty();

    fieldRecordDetailsMapTabCtrl.onPositionChangeFn() &&
      fieldRecordDetailsMapTabCtrl.onPositionChangeFn()(
        txPosition,
        expertPosition,
        expertPositionAccuracy,
        goToFirstTab
      );
  }

  function onLockedTabChange(value) {
    fieldRecordDetailsMapTabCtrl.onLockedTabChangeFn() &&
      fieldRecordDetailsMapTabCtrl.onLockedTabChangeFn()('map', value);
  }

  function setPositionFromGPS() {
    fieldRecordDetailsMapTabCtrl.gpsLoading = true;
    Geolocation.getCurrentPosition().then(
      (position) => {
        fieldRecordDetailsMapTabCtrl.gpsLoading = false;
        // Notification du container : on utilise le GPS pour définir les positions
        var gpsPoint = geoJsonFromLatlng(position.coords.latitude, position.coords.longitude);
        onPositionChange(gpsPoint, gpsPoint, position.coords.accuracy, false);
      },
      () => {
        fieldRecordDetailsMapTabCtrl.gpsLoading = false;
        LxNotificationService.error('Une erreur est survenue lors de la récupération de la position GPS');
      }
    );
  }

  function startEditing() {
    fieldRecordDetailsMapTabCtrl.gpsLoading = true;
    Geolocation.getCurrentPosition()
      .then(
        (position) => {
          fieldRecordDetailsMapTabCtrl.gpsLoading = false;
          // On n'a pas de valeur de txPosition : on initialise à la position du GPS, sinon on garde la valeur initiale
          if (!fieldRecordDetailsMapTabCtrl.txPosition) {
            fieldRecordDetailsMapTabCtrl.currentTxPosition = geoJsonFromLatlng(
              position.coords.latitude,
              position.coords.longitude
            );
          }
          // On définit la position de l'expert à partir des informations du GPS
          fieldRecordDetailsMapTabCtrl.currentExpertPosition = geoJsonFromLatlng(
            position.coords.latitude,
            position.coords.longitude
          );
          fieldRecordDetailsMapTabCtrl.currentExpertPositionAccuracy = position.coords.accuracy;
          fieldRecordDetailsMapTabCtrl.isEditing = 'point';
          onLockedTabChange(true);
        },
        () => {
          fieldRecordDetailsMapTabCtrl.gpsLoading = false;
          fieldRecordDetailsMapTabCtrl.currentExpertPosition = null;
          fieldRecordDetailsMapTabCtrl.currentExpertPositionAccuracy = null;
          LxNotificationService.error('Une erreur est survenue lors de la récupération de la position GPS');
          fieldRecordDetailsMapTabCtrl.isEditing = 'point';
          onLockedTabChange(true);
        }
      )
      .then(updateLayers);
  }

  function onValidateEditing() {
    var editor = fieldRecordDetailsMapTabCtrl.isEditing;
    fieldRecordDetailsMapTabCtrl.isEditing = false;
    onLockedTabChange(false);

    switch(editor){
      case 'point':
        //l'utilisateur fait bouger la carte pour définir une nouvelle position
        //du point à la position currentCenter
        if(fieldRecordDetailsMapTabCtrl.currentCenter){
          fieldRecordDetailsMapTabCtrl.currentTxPosition = geoJsonFromLatlng(
            fieldRecordDetailsMapTabCtrl.currentCenter.lat,
            fieldRecordDetailsMapTabCtrl.currentCenter.lng
          );
        }
        //l'utilisateur ne fait pas bouger la carte pour définir une nouvelle position
        //du point, la nouvelle position est la position par défaut initialPosition
        else{
          fieldRecordDetailsMapTabCtrl.currentTxPosition = fieldRecordDetailsMapTabCtrl.initialPosition.center;
        }
        break;
      case 'draw':
        fieldRecordDetailsMapTabCtrl.currentTxPosition = drawGeom ? drawGeom.features[0].geometry : null;
        break;
    }

    // Notification du container
    onPositionChange(
      fieldRecordDetailsMapTabCtrl.currentTxPosition,
      fieldRecordDetailsMapTabCtrl.currentExpertPosition,
      fieldRecordDetailsMapTabCtrl.currentExpertPositionAccuracy,
      false
    );
  }

  function onCancelEditing() {
    fieldRecordDetailsMapTabCtrl.isEditing = false;
    onLockedTabChange(false);

    // Réinitialisation à partir des données initiales
    fieldRecordDetailsMapTabCtrl.currentExpertPosition = fieldRecordDetailsMapTabCtrl.expertPosition;
    fieldRecordDetailsMapTabCtrl.currentExpertPositionAccuracy = fieldRecordDetailsMapTabCtrl.expertPositionAccuracy;
    fieldRecordDetailsMapTabCtrl.currentTxPosition = fieldRecordDetailsMapTabCtrl.txPosition;

    updateLayers();
  }

  function onMove(center, zoom) {

    StudyMapService.saveCurrentPosition(fieldRecordDetailsMapTabCtrl.studyId, center.lat, center.lng, zoom);
    if (_.get(fieldRecordDetailsMapTabCtrl.fieldSurveyState, 'fieldSurvey.surveyType.protocol.key') == 'RI') {
      fieldRecordDetailsMapTabCtrl.initialPosition = StudyMapService.getCurrentPositionAsGeoJson(
        fieldRecordDetailsMapTabCtrl.studyId
      );
    }
    if (fieldRecordDetailsMapTabCtrl.isEditing == 'point') {
      fieldRecordDetailsMapTabCtrl.currentCenter = center;
    }
  }

  function startDraw() {
    if (drawGeom) {
      fieldRecordDetailsMapTabCtrl.currentTxPosition = drawGeom;
    }

    fieldRecordDetailsMapTabCtrl.isEditing = 'draw';
    updateLayers();
  }

  function onDrawEdited(e) {
    console.log('onDrawEdited', e);
    drawGeom = e;
  }

  function onDrawDeleted() {
    console.log('onDrawDeleted');
    drawGeom = null;
  }
}
