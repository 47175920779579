import UUID from 'uuid-js';
import _ from 'lodash';

import FieldRecordExtraTableUtil from '../tools/extra-table-util.js';
import { EXTRA_TABLE } from '../model.js';

export default FieldRecordRepository;

// @ngInject
function FieldRecordRepository(DatabaseProvider, StorageService, $q, SurveyTypeRepository, MediaRepository) {
  return {
    latest,
    latestTxKeysForStudyAndTxGroups,
    getGeomForFieldSurvey,
    getGeomForStudy,
    getForStudy,
    getForFieldSurvey,
    getForFieldSurveyRp,
    getById,
    getDescription,
    getExtraTables,
    create,
    update,
    updateDescription,
    updateHeadcount,
    deleteFieldRecord,
    makePublicForSurveyType,
    makePublicForFieldSurvey,
    getLatestTxGroupInFieldRecord,
    saveTxGroupInFieldRecord,
    loadExtraTablesForClonage,
    insertFieldRecordExtraTableForClone,
    validateFieldRecord,
    getExtraDataToggle,
    setExtraDataToggle,
    loadFieldRecordExtraDatas,
    loadExtraTables,
  };

  function getLatestTxGroupInFieldRecord() {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    return window.localStorage.getItem('txkey.latest-in-field-survey');
  }

  function saveTxGroupInFieldRecord(txKey) {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    if (!txKey) {
      return;
    }
    window.localStorage.setItem('txkey.latest-in-field-survey', txKey);
  }

  function latest(limit) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
              SELECT DISTINCT
                fr.id as fr_id,
                fr.key as fr_key,
                fr.description as fr_description,
                fr.expert as fr_expert,
                fr.expert_position as fr_expertPosition,
                fr.expert_position_accuracy as fr_expertPositionAccuracy,
                fr.tx_position as fr_txPosition,
                fr.tx_ref_type as fr_txRefType,
                fr.tx_ref_filter as fr_txRefFilter,
                fr.tx_ref_version as fr_txRefVersion,
                fr.tx_key as fr_txKey,
                fr.tx_name as fr_txName,
                fr.is_tx_sure as fr_isTxSure,
                fr.is_tx_present as fr_isTxPresent,
                fr.tx_headcount as fr_txHeadcount,
                fr.tx_headcount_accuracy as fr_txHeadcountAccuracy,
                fr.tx_time as fr_txTime,
                fr.tx_group as fr_txGroup,
                fr.status as fr_status,
                fr.created as fr_created,
                fr.created_by as fr_createdBy,
                fr.updated as fr_updated,
                fr.updated_by as fr_updatedBy,
                fr.deleted as fr_deleted,
                fr.deleted_by as fr_deletedBy,
                fr.ghost as fr_ghost,
                s.id as s_id,
                s.key as s_key,
                s.name as s_name,
                s.is_biblio as s_isBiblio,
                s.biblio_src as s_biblioSrc,
                s.start_time as s_startTime,
                s.timezone as s_timezone,
                s.end_time as s_endTime,
                s.application_key as s_applicationKey,
                s.access_constraint as s_accessConstraint,
                s.use_constraint as s_useConstraint,
                s.status as s_status,
                s.created as s_created,
                s.created_by as s_createdBy,
                s.updated as s_updated,
                s.updated_by as s_updatedBy,
                s.deleted as s_deleted,
                s.deleted_by as s_deletedBy,
                fs.id as fs_id,
                fs.key as fs_key,
                fs.name as fs_name,
                fs.description as fs_description,
                fs.terr_unit as fs_terrUnit,
                fs.terr_unit_name as fs_terrUnitName,
                fs.place as fs_place,
                fs.expert as fs_expert,
                fs.start_time as fs_startTime,
                fs.end_time as fs_endTime,
                fs.survey_type as fs_surveyType,
                fs.created as fs_created,
                fs.created_by as fs_createdBy,
                fs.updated as fs_updated,
                fs.updated_by as fs_updatedBy,
                fs.deleted as fs_deleted,
                fs.deleted_by as fs_deletedBy
              FROM field_record fr
              LEFT JOIN field_survey fs on (fs.id = fr.field_survey)
              LEFT JOIN survey_type st on (st.id = fs.survey_type)
              JOIN study s on (s.id = fr.study)
              WHERE (fr.deleted is null or fr.deleted > datetime())
              AND (fs.deleted is null or fs.deleted > datetime())
              AND (st.deleted is null or st.deleted > datetime())
              AND (s.deleted is null or s.deleted > datetime())
              AND (fr.ghost = 0)
              ORDER BY fr.tx_time DESC
              LIMIT ?
            `,
        [limit]
      ).then((fieldRecordResults) => {
        return _.map(fieldRecordResults, (record) => {
          var res = {};
          _.forOwn(record, function(value, key) {
            if (_.startsWith(key, 's_')) {
              _.set(res, 'study.' + key.substr(2), value);
              return;
            }
            if (_.startsWith(key, 'fs_')) {
              _.set(res, 'fieldSurvey.' + key.substr(3), value);
              return;
            }
            if (_.startsWith(key, 'fr_')) {
              _.set(res, key.substr(3), value);
              return;
            }
          });
          return res;
        });
      });
    });
  }

  function latestTxKeysForStudyAndTxGroups(studyId, txGroupKeys, limit) {
    return DatabaseProvider.getDatabase().then((database) => {
      var txGroupsCondition = '';
      if (txGroupKeys && txGroupKeys.length) {
        var params = _.times(txGroupKeys.length, () => '?').join(',');
        txGroupsCondition = `AND tx_group IN (${params})`;
      }

      return StorageService.executeSqlQuery(
        database,
        `
              SELECT DISTINCT tx_key as txKey
              FROM field_record
              WHERE (deleted is null or deleted > datetime())
              AND study = ?
              ${txGroupsCondition}
              ORDER BY tx_time DESC
              LIMIT ?
            `,
        _.chain([studyId, txGroupKeys, limit]).flatten().filter().value()
      ).then((results) => _.map(results, 'txKey'));
    });
  }

  function getGeomForFieldSurvey(fieldSurveyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
              SELECT DISTINCT
                fr.id as id,
                fr.key as key,
                fr.tx_position as txPosition,
                fr.tx_name as txName,
                fr.tx_headcount as txHeadcount,
                fr.tx_headcount_accuracy as txHeadcountAccuracy
              FROM field_record fr
              WHERE (fr.deleted is null or fr.deleted > datetime())
              AND fr.ghost = 0
              AND (fr.field_survey = ?)
              AND (fr.tx_position is not null)
            `,
        [fieldSurveyId]
      ).then((fieldRecords) =>
        _.map(fieldRecords, (fieldRecord) =>
          _.defaults({ txPosition: StorageService.parseGeoJsonData(fieldRecord.txPosition) }, fieldRecord)
        )
      );
    });
  }

  function getGeomForStudy(studyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
              SELECT DISTINCT
                fr.id as id,
                fr.key as key,
                fr.tx_position as txPosition,
                fr.tx_name as txName,
                fr.tx_headcount as txHeadcount,
                fr.tx_headcount_accuracy as txHeadcountAccuracy
              FROM field_record fr
              JOIN field_survey fs ON (fs.id = fr.field_survey)
              JOIN survey_type st ON (st.id = fs.survey_type)
              WHERE (fr.deleted is null or fr.deleted > datetime())
              AND (fs.deleted is null or fs.deleted > datetime())
              AND (st.deleted is null or st.deleted > datetime())
              AND fr.ghost = 0
              AND (fr.study = ?)
              AND (fr.tx_position is not null)
            `,
        [studyId]
      ).then((fieldRecords) =>
        _.map(fieldRecords, (fieldRecord) =>
          _.defaults({ txPosition: StorageService.parseGeoJsonData(fieldRecord.txPosition) }, fieldRecord)
        )
      );
    });
  }

  function getForStudy(studyId, onlyOnTheFly = false, withDeleted = false) {
    return DatabaseProvider.getDatabase().then((database) => {
      let onTheFlyFilter = onlyOnTheFly ? ' AND fr.field_survey is null ' : '';
      let withDeletedFilter = withDeleted
        ? ''
        : ' AND (fs.deleted is null OR fs.deleted > datetime()) AND ' +
          '(st.deleted is null OR st.deleted > datetime()) AND (fr.deleted is null OR fr.deleted > datetime()) ';

      let fieldRecordPromise = StorageService.executeSqlQuery(
        database,
        `
            SELECT DISTINCT
              fr.id,
              fr.key,
              fr.description,
              fr.study,
              fr.field_survey AS fieldSurvey,
              '' AS expertName,
              fr.expert,
              fr.expert_position AS expertPosition,
              fr.expert_position_accuracy AS expertPositionAccuracy,
              fr.tx_position AS txPosition,
              fr.tx_ref_type AS txRefType,
              fr.tx_ref_version AS txRefVersion,
              fr.tx_ref_filter as txRefFilter,
              fr.tx_key AS txKey,
              fr.tx_name AS txName,
              fr.tx_vernacular_name AS txVernacularName,
              fr.is_tx_sure AS isTxSure,
              fr.is_tx_present AS isTxPresent,
              fr.tx_headcount AS txHeadcount,
              fr.tx_headcount_accuracy AS txHeadcountAccuracy,
              fr.tx_time AS txTime,
              fr.tx_group AS txGroup,
              fr.status,
              fr.created,
              fr.created_by AS createdBy,
              fr.updated,
              fr.updated_by AS updatedBy,
              fr.deleted,
              fr.deleted_by AS deletedBy,
              fr.ghost,
              st.id AS surveyType
            FROM field_record fr
            LEFT JOIN field_survey fs ON (fs.id = fr.field_survey)
            LEFT JOIN survey_type st ON (st.id = fs.survey_type)
            WHERE fr.study = ?
            AND fr.ghost = 0
            ${onTheFlyFilter}
            ${withDeletedFilter}
            ORDER BY tx_name ASC
          `,
        [studyId]
      ).then((fieldRecords) =>
        _.map(fieldRecords, (fieldRecord) => {
          // Désérialisation des geométries
          let defaultValues = {
            expertPosition: StorageService.parseGeoJsonData(fieldRecord.expertPosition),
            txPosition: StorageService.parseGeoJsonData(fieldRecord.txPosition),
          };
          return _.defaults(defaultValues, fieldRecord);
        })
      );
      return fieldRecordPromise;
    });
  }

  function getForFieldSurvey(fieldSurveyId, withDeleted = false) {
    return DatabaseProvider.getDatabase().then((database) => {
      let withDeletedFilter = withDeleted ? '' : ' AND (fr.deleted is null OR fr.deleted > datetime()) ';
      //              
      let fieldRecordPromise = StorageService.executeSqlQuery(
        database,
        `
            SELECT DISTINCT
              fr.id,
              fr.key,
              fr.description,
              fr.study,
              fr.field_survey AS fieldSurvey,
              fr.expert,
              fr.expert_position AS expertPosition,
              fr.expert_position_accuracy AS expertPositionAccuracy,
              fr.tx_position AS txPosition,
              fr.tx_ref_type AS txRefType,
              fr.tx_ref_version AS txRefVersion,
              fr.tx_ref_filter AS txRefFilter,
              fr.tx_key AS txKey,
              fr.tx_name AS txName,
              fr.tx_vernacular_name AS txVernacularName,
              fr.is_tx_sure AS isTxSure,
              fr.is_tx_present AS isTxPresent,
              fr.tx_headcount AS txHeadcount,
              fr.tx_headcount_accuracy AS txHeadcountAccuracy,
              fr.tx_time AS txTime,
              fr.tx_group AS txGroup,
              fr.status,
              fr.created,
              fr.created_by AS createdBy,
              fr.updated,
              fr.updated_by AS updatedBy,
              fr.deleted,
              fr.deleted_by AS deletedBy,
              fr.ghost
            FROM field_record fr
            WHERE fr.field_survey = ?
            ${withDeletedFilter}
            ORDER BY tx_name ASC
          `,
        [fieldSurveyId]
      ).then((fieldRecords) =>
        _.map(fieldRecords, (fieldRecord) => {
          // Désérialisation des geométries
          let defaultValues = {
            expertPosition: StorageService.parseGeoJsonData(fieldRecord.expertPosition),
            txPosition: StorageService.parseGeoJsonData(fieldRecord.txPosition),
            isTxSure: !!fieldRecord.isTxSure,
            isTxPresent: !!fieldRecord.isTxPresent,
          };
          return _.defaults(defaultValues, fieldRecord);
        })
      );
      return fieldRecordPromise;
    });
  }

  function getForFieldSurveyRp(fieldSurveyId) {
    return new Promise((resolve, reject) => {
      getForFieldSurvey(fieldSurveyId).then((records) => {
        DatabaseProvider.getDatabase().then((database) => {
          getExtraTableByName(
            database,
            EXTRA_TABLE.RP_PLANTS,
            records.map((record) => record.id)
          ).then((extras) => {
            const results = records.map((record) => {
              return {
                ...record,
                extraTables: extras.find((extra) => {
                  return extra.RP_PLANTS.id === record.id;
                }),
              };
            });
            resolve(results);
          });
        });
      });
    });
  }

  function getById(fieldRecordId, withFullDetails = false) {
    return DatabaseProvider.getDatabase().then((database) => {
      //                
      return StorageService.executeSqlQuery(
        database,
        `
              SELECT DISTINCT
                fr.id as fr_id,
                fr.key as fr_key,
                fr.description as fr_description,
                fr.expert as fr_expert,
                fr.expert_position as fr_expertPosition,
                fr.expert_position_accuracy as fr_expertPositionAccuracy,
                fr.tx_position as fr_txPosition,
                fr.tx_ref_type as fr_txRefType,
                fr.tx_ref_version as fr_txRefVersion,
                fr.tx_ref_filter as fr_txRefFilter,
                fr.tx_key as fr_txKey,
                fr.tx_name as fr_txName,
                fr.is_tx_sure as fr_isTxSure,
                fr.is_tx_present as fr_isTxPresent,
                fr.tx_headcount as fr_txHeadcount,
                fr.tx_headcount_accuracy as fr_txHeadcountAccuracy,
                fr.tx_time as fr_txTime,
                fr.tx_group as fr_txGroup,
                fr.status as fr_status,
                fr.created as fr_created,
                fr.created_by as fr_createdBy,
                fr.updated as fr_updated,
                fr.updated_by as fr_updatedBy,
                fr.deleted as fr_deleted,
                fr.deleted_by as fr_deletedBy,
                fr.ghost as fr_ghost,
                s.id as s_id,
                s.key as s_key,
                s.name as s_name,
                s.is_biblio as s_isBiblio,
                s.biblio_src as s_biblioSrc,
                s.start_time as s_startTime,
                s.timezone as timezone,
                s.end_time as s_endTime,
                s.application_key as s_applicationKey,
                s.access_constraint as s_accessConstraint,
                s.use_constraint as s_useConstraint,
                s.status as s_status,
                s.created as s_created,
                s.created_by as s_createdBy,
                s.updated as s_updated,
                s.updated_by as s_updatedBy,
                s.deleted as s_deleted,
                s.deleted_by as s_deletedBy,
                fs.id as fs_id,
                fs.key as fs_key,
                fs.name as fs_name,
                fs.description as fs_description,
                fs.terr_unit as fs_terrUnit,
                fs.terr_unit_name as fs_terrUnitName,
                fs.place as fs_place,
                fs.expert as fs_expert,
                fs.start_time as fs_startTime,
                fs.end_time as fs_endTime,
                fs.survey_type as fs_surveyType,
                fs.created as fs_created,
                fs.created_by as fs_createdBy,
                fs.updated as fs_updated,
                fs.updated_by as fs_updatedBy,
                fs.deleted as fs_deleted,
                fs.deleted_by as fs_deletedBy
              FROM field_record fr
              LEFT JOIN field_survey fs on (fs.id = fr.field_survey)
              LEFT JOIN survey_type st on (st.id = fs.survey_type)
              JOIN study s on (s.id = fr.study)
              WHERE fr.id = ?
            `,
        [fieldRecordId]
      )
        .then(([record]) => {
          var res = {};
          _.forOwn(record, function(value, key) {
            if (_.startsWith(key, 's_')) {
              _.set(res, 'study.' + key.substr(2), value);
              return;
            }
            if (_.startsWith(key, 'fs_')) {
              _.set(res, 'fieldSurvey.' + key.substr(3), value);
              return;
            }
            if (_.startsWith(key, 'fr_')) {
              if (key === 'fr_txPosition' || key === 'fr_expertPosition') {
                _.set(res, key.substr(3), StorageService.parseGeoJsonData(value));
              } else {
                _.set(res, key.substr(3), value);
              }
              return;
            }
          });
          if (!res.fieldSurvey.id) {
            // l'observation obtenue n'est pas liée à un relevé
            res.fieldSurvey = null;
          }
          return res;
        })
        .then((fieldRecord) => {
          return _.defaults(
            {
              isTxSure: !!fieldRecord.isTxSure,
              isTxPresent: !!fieldRecord.isTxPresent,
              ghost: !!fieldRecord.ghost,
              canEdit: true,
            },
            fieldRecord
          );
        })
        .then((fieldRecord) => {
          if (!withFullDetails) {
            return fieldRecord;
          }

          return loadFieldRecordExtraDatas(database, fieldRecord);
        });
    });
  }

  function loadFieldRecordExtraDatas(database, fieldRecord) {
    return loadMedias(database, fieldRecord.id)
      .then((medias) => {
        return _.defaults({ medias, canEdit: true }, fieldRecord);
      })
      .then((fieldRecord) => {
        if (!fieldRecord.fieldSurvey) {
          return fieldRecord;
        }
        return loadExtraTables(
          database,
          fieldRecord.id,
          fieldRecord.txGroup,
          fieldRecord.fieldSurvey.id || fieldRecord.fieldSurvey
        ).then((extraTables) => {
          return _.merge(extraTables, fieldRecord);
        });
      });
  }

  function loadExtraTables(database, fieldRecordId, txGroup, fieldSurveyId, protocolId) {
    if (!fieldRecordId || !txGroup || (!fieldSurveyId && !protocolId)) {
      return $q.when([]);
    }
    const isMultipleRecords = Array.isArray(fieldRecordId);
    const getFormatedRow = (extraTable, input) => {
      const row = { [extraTable]: _.mapKeys(input, (value, key) => _.camelCase(key)) };
      if (row && row['GENETIC_SAMPLING']) {
        try {
          row['GENETIC_SAMPLING']['pictureNames'] = JSON.parse(row['GENETIC_SAMPLING']['pictureNames']);
        } catch (error) {
          row['GENETIC_SAMPLING']['pictureNames'] = [];
        }
      }
      return row;
    };
    const callGetExtraTables = !isMultipleRecords
      ? getExtraTables(fieldSurveyId, txGroup)
      : getExtraTables(null, txGroup, protocolId);

    return callGetExtraTables.then((extraTables) => {
      var promises = _.map(extraTables, (extraTable) => {
        var extraTableName = FieldRecordExtraTableUtil.toTableName(extraTable);
        const query = isMultipleRecords
          ? StorageService.executeSqlQuery(
              database,
              `SELECT * FROM ${extraTableName} WHERE id in (${fieldRecordId.join(',')})`
            )
          : StorageService.executeSqlQuery(database, `SELECT * FROM ${extraTableName} WHERE id = ?`, [fieldRecordId]);

        return query.then((extraTableValueResults) => {
          if (extraTableValueResults.length) {
            if (!isMultipleRecords) {
              return getFormatedRow(extraTable, extraTableValueResults[0]);
            }
            return extraTableValueResults.map((res) => {
              return getFormatedRow(extraTable, res);
            });
          }

          return isMultipleRecords ? [] : { [extraTable]: null };
        });
      });
      return $q.all(promises).then((extraTableMaps) => {
        if (isMultipleRecords) {
          return extraTableMaps.flat();
        }
        var extraTables = _.reduce(extraTableMaps, _.merge, {});
        return { extraTables };
      });
    });
  }

  function getExtraTableByName(database, name, fieldRecordIds) {
    return StorageService.executeSqlQuery(
      database,
      `SELECT * FROM ${FieldRecordExtraTableUtil.toTableName(name)} WHERE id in (${fieldRecordIds.join(',')})`
    ).then((extraTableValueResults) => {
      return extraTableValueResults.map((res) => {
        return { [name]: _.mapKeys(res, (value, key) => _.camelCase(key)) };
      });
    });
  }

  function loadExtraTablesForClonage(database, fieldRecordId, txGroup, fieldSurveyId) {
    if (!fieldRecordId || !txGroup || !fieldSurveyId) {
      return $q.when([]);
    }
    return getExtraTables(fieldSurveyId, txGroup).then((extraTables) => {
      var promises = _.map(extraTables, (extraTable) => {
        // TODO: toInfoFieldsToClone
        var fieldsToClone = FieldRecordExtraTableUtil.toInfoFieldsToClone(extraTable);
        if (!fieldsToClone || fieldsToClone.length === 0) {
          return { [extraTable]: null };
        }
        var extraTableName = FieldRecordExtraTableUtil.toTableName(extraTable);
        return StorageService.executeSqlQuery(
          database,
          `SELECT ${fieldsToClone.join(',')} FROM ${extraTableName} WHERE id = ?`,
          [fieldRecordId]
        ).then((extraTableValueResults) => {
          if (extraTableValueResults.length) {
            // On passe les clés de l'objet en camelcase
            return { [extraTable]: _.mapKeys(extraTableValueResults[0], (value, key) => _.camelCase(key)) };
          }

          return { [extraTable]: null };
        });
      });
      return $q.all(promises).then((extraTableMaps) => {
        var extraTables = _.reduce(extraTableMaps, _.merge, {});
        return extraTables;
      });
    });
  }

  function loadMedias(database, fieldRecordId) {
    return new Promise((resolve, reject) => {
      resolve([]);
    });
    /* return StorageService.executeSqlQuery(
      database,
      `
      SELECT
        m.id,
        m.mime_type AS mimeType,
        m.filename,
        m.created,
        m.updated,
        m.deleted
      FROM media m
      JOIN field_record_media frm ON (frm.media = m.id)
      WHERE frm.field_record = ?
      `,
      [fieldRecordId]
    ).then((medias) => {
      var mediaPromises = _.map(medias, (media) => {
        return MediaRepository.checkAndGetMediaLocalPath(media).then(
          (localPath) => _.merge({ localPath }, media),
          () => {
            return MediaRepository.getMediaHref(media).then((href) => _.merge({ href }, media));
          }
        );
      });
      return $q.all(mediaPromises);
    }); */
  }
  ////////////////////////////////////////////

  function getDescription(fieldRecordId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
              SELECT id, description, ghost
              FROM field_record
              WHERE id = ?
            `,
        [fieldRecordId]
      ).then(([fieldRecord]) => _.merge({ canEdit: !fieldRecord.ghost }, fieldRecord));
    });
  }

  function getExtraTables(fieldSurveyId, txGroup, protocolId) {
    const sql = !protocolId
      ? `SELECT ptg.field_record_extra_table
        FROM field_survey fs
        JOIN survey_type st ON (st.id = fs.survey_type)
        JOIN protocol_tx_group ptg ON (ptg.protocol = st.protocol)
        WHERE fs.id = ?
        AND ptg.tx_group = ?
      `
      : `SELECT ptg.field_record_extra_table
        FROM protocol_tx_group ptg
        WHERE ptg.protocol = ?
        AND ptg.tx_group = ?
      `;
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(database, sql, [fieldSurveyId || protocolId, txGroup]).then(([record]) => {
        if (record && record.field_record_extra_table) {
          return StorageService.parseArrayData(record.field_record_extra_table).sort();
        }
        return [];
      });
    });
  }

  function create(studyId, fieldRecordForm, fieldRecordExtraTableForms) {

    var {
      description,
      fieldSurveyId,
      txRefType,
      txRefVersion,
      txKey,
      txName,
      txVernacularName,
      txSure,
      txPresent,
      txHeadcount,
      txHeadcountAccuracy,
      txTime,
      txGroupKey,
      status,
      txPosition,
      expertPosition,
      expertPositionAccuracy,
      ghost,
      txRefFilter
    } = fieldRecordForm;
    //on met un effectif à 1 et précision à minimum par défaut quand une abondance est renseignée pour les RP
    //https://b5e.atlassian.net/browse/S6N-49
    if(fieldRecordExtraTableForms && fieldRecordExtraTableForms.RP_PLANTS &&  fieldRecordExtraTableForms.RP_PLANTS.abondanceDominance){

      txHeadcount = 1;
      txHeadcountAccuracy = 'MINIMUM';

    }
    var now = new Date().getTime() / 1000;
    return DatabaseProvider.getDatabase().then((database) => {
      // On ne met pas created_by ni updated_by, cela se fera à la synchro
      return StorageService.executeSqlQuery(
          database,
          `
            INSERT INTO field_record (key, study, field_survey, description, tx_ref_type, tx_ref_version, tx_key,
              tx_name, tx_vernacular_name, is_tx_sure, is_tx_present, tx_headcount, tx_headcount_accuracy,
              tx_time, tx_group, status, tx_position, expert_position, expert_position_accuracy, created, updated, ghost, tx_ref_filter)
            VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
          `,
          [
            UUID.create().toString(), 
            studyId, 
            fieldSurveyId, 
            description, 
            txRefType, 
            txRefVersion, 
            txKey,
            txName, 
            txVernacularName, 
            txSure, 
            txPresent, 
            txHeadcount, 
            txHeadcountAccuracy,
            txTime, 
            txGroupKey, 
            status, 
            txPosition, 
            expertPosition, 
            expertPositionAccuracy, 
            now, 
            now, 
            ghost, 
            txRefFilter
          ]
      ).then((id) => {
        return createExtraTabs(database, id, fieldRecordExtraTableForms).then(() => getById(id));
      });
    });
  }

  function update(fieldRecordId, fieldRecordForm, mediaForm, fieldRecordExtraTableForms) {
    var {
      description,
      txRefType,
      txRefVersion,
      txKey,
      txName,
      txVernacularName,
      txSure,
      txPresent,
      txHeadcount,
      txHeadcountAccuracy,
      txTime,
      status,
      txPosition,
      expertPosition,
      expertPositionAccuracy,
      ghost,
      txRefFilter
    } = fieldRecordForm;
    var now = new Date().getTime() / 1000;

    return DatabaseProvider.getDatabase().then((database) => {
      var promises = [];
      if (mediaForm.addedMediaIds && mediaForm.addedMediaIds.length > 0) {
        let sql = 'INSERT INTO field_record_media (field_record, media) VALUES (?, ?)';
        for (let i = 0; i < mediaForm.addedMediaIds.length; i++) {
          promises.push(StorageService.executeSqlQuery(database, sql, [fieldRecordId, mediaForm.addedMediaIds[i]]));
        }
      }

      if (mediaForm.removedMediaIds && mediaForm.removedMediaIds.length > 0) {
        let sql = `
            DELETE FROM field_record_media
            WHERE field_record = ?
            AND media IN (${_.times(mediaForm.removedMediaIds.length, () => '?').join(',')})
          `;
        promises.push(
          StorageService.executeSqlQuery(database, sql, [].concat([fieldRecordId], mediaForm.removedMediaIds))
        );
      }
      //on met un effectif à 1 et précision à minimum par défaut quand une abondance est renseignée pour les RP
      //https://b5e.atlassian.net/browse/S6N-49
      if(fieldRecordExtraTableForms && fieldRecordExtraTableForms.RP_PLANTS &&  fieldRecordExtraTableForms.RP_PLANTS.abondanceDominance){

        txHeadcount = 1;
        txHeadcountAccuracy = 'MINIMUM';
  
      }

      // On ne met pas created_by ni updated_by, cela se fera à la synchro

      promises.push(
        StorageService.executeSqlQuery(
          database,
          `
          UPDATE field_record
          SET
            description = ?,
            tx_ref_type = ?,
            tx_ref_version = ?,
            tx_ref_filter = ?,
            tx_key = ?,
            tx_name = ?,
            tx_vernacular_name = ?,
            is_tx_sure = ?,
            is_tx_present = ?,
            tx_headcount = ?,
            tx_headcount_accuracy = ?,
            tx_time = ?,
            status = ?,
            tx_position = ?,
            expert_position = ?,
            expert_position_accuracy = ?,
            updated = ?,
            ghost = ?
          WHERE id = ?
        ` ,
          [
            description,
            txRefType,
            txRefVersion,
            txRefFilter,
            txKey,
            txName,
            txVernacularName,
            txSure,
            txPresent,
            txHeadcount,
            txHeadcountAccuracy,
            txTime,
            status,
            txPosition,
            expertPosition,
            expertPositionAccuracy,
            now,
            ghost,
            fieldRecordId,
          ]
        ).then(() => createExtraTabs(database, fieldRecordId, fieldRecordExtraTableForms))
      );

      return $q.all(promises).then(() => getById(fieldRecordId));
    });
  }

  function createExtraTabs(database, fieldRecordId, fieldRecordExtraTableForms) {
    var promises = [];

    for (let extraTable in fieldRecordExtraTableForms) {
      promises.push(
        createExtraTabPromise(
          database,
          fieldRecordId,
          fieldRecordExtraTableForms[extraTable],
          FieldRecordExtraTableUtil.toTableName(extraTable)
        )
      );
    }

    return $q.all(promises);
  }

  function createExtraTabPromise(database, fieldRecordId, extraTableData, extraTableName) {
    var sql = `INSERT OR REPLACE INTO ${extraTableName}(id`;
    var parameters = [fieldRecordId];

    for (let field in extraTableData) {
      sql += ', ' + _.snakeCase(field);
      parameters.push(extraTableData[field]);
    }

    sql += ') VALUES (?' + _.repeat(', ?', _.size(extraTableData)) + ')';

    return StorageService.executeSqlQuery(database, sql, parameters);
  }

  function insertFieldRecordExtraTableForClone(database, fieldRecordId, extraTableData, extraTable) {
    var extraTableName = FieldRecordExtraTableUtil.toTableName(extraTable);
    var fieldsToClone = FieldRecordExtraTableUtil.toInfoFieldsToClone(extraTable);

    if (!fieldsToClone) {
      return $q.when(null);
    }

    var sql =
      `INSERT OR REPLACE INTO ${extraTableName}( id, ${fieldsToClone}) VALUES (?` +
      _.repeat(', ?', fieldsToClone.length) +
      ')';
    var parameters = [fieldRecordId];

    _.forEach(fieldsToClone, (fieldToClone) => {
      parameters.push(extraTableData[_.camelCase(fieldToClone)]);
    });
    return StorageService.executeSqlQuery(database, sql, parameters);
  }

  function deleteExtraTabPromise(database, fieldRecordId, extraTableName) {
    var sql = `DELETE FROM ${extraTableName} WHERE id = ?`;
    var parameters = [fieldRecordId];

    return StorageService.executeSqlQuery(database, sql, parameters);
  }

  function updateDescription(fieldRecordId, description) {
    return DatabaseProvider.getDatabase().then((database) => {
      // On ne met pas à jour updated_by, cela se fera à la synchro
      return StorageService.executeSqlQuery(
        database,
        `
          UPDATE field_record
          SET description = ?, updated = ?
          WHERE id = ?
        `,
        [description, new Date().getTime() / 1000, fieldRecordId]
      );
    });
  }

  function updateHeadcount(fieldRecordId, headcount) {
    return DatabaseProvider.getDatabase().then((database) => {
      // On ne met pas à jour updated_by, cela se fera à la synchro
      return StorageService.executeSqlQuery(
        database,
        `
          UPDATE field_record
          SET tx_headcount = ?, updated = ?
          WHERE id = ?
        `,
        [headcount, new Date().getTime() / 1000, fieldRecordId]
      );
    });
  }

  function validateFieldRecord(fieldRecordId, headcount) {
    return DatabaseProvider.getDatabase().then((database) => {
      // On ne met pas à jour updated_by, cela se fera à la synchro
      let now = new Date().getTime() / 1000;
      return StorageService.executeSqlQuery(
        database,
        `
          UPDATE field_record
          SET tx_headcount = ?, ghost = ?,  updated = ?, tx_time = ?
          WHERE id = ? AND ghost = ?
        `,
        [headcount, false, now, now, fieldRecordId, true]
      );
    });
  }

  function deleteFieldRecord(fieldRecordId) {
    var now = new Date().getTime() / 1000;
    return DatabaseProvider.getDatabase().then((database) => {
      // On ne met pas à jour updated_by, cela se fera à la synchro
      return StorageService.executeSqlQuery(
        database,
        `
          UPDATE field_record
          SET updated = ?, deleted = ?
          WHERE id = ?
        `,
        [now, now, fieldRecordId]
      );
    });
  }

  function makePublicForSurveyType(surveyTypeId) {
    return DatabaseProvider.getDatabase().then((database) => {
      // On ne met pas à jour updated_by, cela se fera à la synchro
      return StorageService.executeSqlQuery(
        database,
        `
          UPDATE field_record
          SET status = 'PUBLIC', updated = ?
          WHERE field_survey IN (SELECT id FROM field_survey WHERE survey_type = ?)
          AND (deleted IS NULL OR deleted > datetime())
        `,
        [new Date().getTime() / 1000, surveyTypeId]
      );
    });
  }

  function makePublicForFieldSurvey(fieldSurveyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      // On ne met pas à jour updated_by, cela se fera à la synchro
      return StorageService.executeSqlQuery(
        database,
        `
          UPDATE field_record
          SET status = 'PUBLIC', updated = ?
          WHERE field_survey = ?
          AND (deleted IS NULL OR deleted > datetime())
        `,
        [new Date().getTime() / 1000, fieldSurveyId]
      );
    });
  }

  function getExtraDataToggle() {
    return window.localStorage && window.localStorage.getItem('field-record.extra-data-toggle') === 'true';
  }

  function setExtraDataToggle(extraDataToggle) {
    window.localStorage && window.localStorage.setItem('field-record.extra-data-toggle', extraDataToggle);
  }
}
