import Leaflet from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-draw';
import 'leaflet.locatecontrol';
import _ from 'lodash';

import { KML } from '../../leaflet/kml';
import { LAYERS_CONFIG } from '../../configuration';
import { IMAGERY_SET } from '../../model';

require('./map-container.scss');

export default MapContainerDirective;

// @ngInject
function MapContainerDirective(
  $timeout,
  LayerProvider,
  $q,
  $filter,
  SettingsStore,
  MapBackgroundsService,
  SettingsOppService
) {
  return {
    restrict: 'E',
    scope: {
      layersProvider: '&layers',
      imagerySetsProvider: '&imagerySets',
      noLocateControlFn: '&noLocateControl',
      onMoveFn: '&onMove',
      mapCenterMarkerProvider: '&mapCenterMarker',
      defaultLayer: '@',
      localLayersVisibilityProvider: '&localLayersVisibility',
      viewZoomProvider: '&viewZoom',
      viewCenterProvider: '&viewCenter',
      onLayerChangedFn: '&onLayerChanged',
      onLocalLayerChangedFn: '&onLocalLayerChanged',
      databasePrefix: '@',
      showDrawProvider: '&showDraw',
      //DEPRECATED geomProvider: '&geom',
      onDrawCreatedFn: '&onDrawCreated',
      onDrawEditedFn: '&onDrawEdited',
      onDrawDeletedFn: '&onDrawDeleted',
      onMapReadyFn: '&onMapReady',
    },
    template: require('./map-container.html'),
    replace: true,
    link: ($scope, jqElement) => {
      var locateControl;
      var mapInitialized = false;
      var layersInitialized = false;
      var imagerySetsInitialized = false;
      var overlays = {};
      var imagerySets = [];
      var visibleOverlays = {};
      var bounds;
      var fitBounds;
      var map;
      var initialCenter;
      var initialZoom;
      var layersControl;
      var mapCenterLayer;
      var mapCenterMarker;
      var doNotify = true;
      var drawnItems;
      var drawControl;
      var drawControlEditOnly;



      $scope.$watch($scope.imagerySetsProvider, (values) => {
        imagerySets = values || IMAGERY_SET.VALUES;

        if (!imagerySetsInitialized) {
          imagerySetsInitialized = true;
          addLayersToMap();
        }
      });

      $scope.$watch($scope.layersProvider, (layers) => {
        bounds = undefined;
        fitBounds = undefined;
        overlays = {};
        visibleOverlays = {};

        var localLayersVisibility = $scope.localLayersVisibilityProvider() || [];
        _.forEach(layers, (layer) => {
          var overlay;
          if (layer.features) {
            overlay = Leaflet.geoJson(layer.features, _.merge({ pane: 'data' }, layer.config));
            
            //deal with clustering if necessary
            if(layer.name ==="Observations" && layer.features.length>10000){
              overlay = fieldRecordClustering(overlay);
            }

            // Couche visible par défaut
            visibleOverlays[layer.name] = overlay;
          } else if (layer.circle) {
            var circle = layer.circle;
            overlay = Leaflet.circle(
              [circle.point.coordinates[1], circle.point.coordinates[0]],
              _.merge({ pane: 'data', radius: circle.radius }, circle.style)
            );
            // Couche visible par défaut
            visibleOverlays[layer.name] = overlay;
          } else if (layer.kml) {
            // KML
            overlay = new KML(layer.url, {
              id: layer.id,
              async: true,
              pane: 'kml',
              remote: layer.remote,
              headers: layer.headers,
            });
            if (_.includes(localLayersVisibility, layer.id)) {
              // Couche visible par défaut
              visibleOverlays[layer.name] = overlay;
            }
          } else if (layer.mbtiles) {
            // MBTiles
            overlay = LayerProvider.provideLocalLayer(layer.filepath, { id: layer.id, pane: 'mbtiles' });
            if (_.includes(localLayersVisibility, layer.id)) {
              // Couche visible par défaut
              visibleOverlays[layer.name] = overlay;
            }
          } else if (layer.config.controlEventHooks) {
            var overlay = Leaflet.geoJSON(null, _.merge({ pane: 'data' }, layer.config));
          } else {
            // type de layer non géré
            return;
          }

          overlays[layer.name] = overlay;
          // Calcul de l'emprise des couches
          if (layer.features || layer.kml) {
            if (bounds) {
              bounds = bounds.extend(overlay.getBounds());
            } else {
              bounds = Leaflet.latLngBounds(overlay.getBounds().getNorthEast(), overlay.getBounds().getSouthWest());
            }
          }
          // Calcul de l'emprise à afficher
          if (layer.fitBounds && overlay.getBounds()) {
            if (fitBounds) {
              fitBounds = fitBounds.extend(overlay.getBounds());
            } else {
              fitBounds = Leaflet.latLngBounds(overlay.getBounds().getNorthEast(), overlay.getBounds().getSouthWest());
            }
          }
        });
        layersInitialized = true;
        addLayersToMap();
      });
      $scope.$watch($scope.viewCenterProvider, (viewCenter) => {
        // on récupère une géométrie geojson, on centre dessus
        if (viewCenter) {
          initialCenter = Leaflet.geoJson(viewCenter).getBounds().getCenter();
        } else {
          initialCenter = undefined;
        }
        updateViewByPosition();
      });
      $scope.$watch($scope.viewZoomProvider, (viewZoom) => {
        initialZoom = viewZoom;
        updateViewByPosition();
      });
      $scope.$watch($scope.mapCenterMarkerProvider, (marker) => {
        if (!marker && mapCenterLayer) {
          map.removeLayer(mapCenterLayer);
          mapCenterLayer = undefined;
          return;
        }
        if (marker && !mapCenterLayer) {
          mapCenterMarker = marker;
          initializeMapCenterLayer();
        }
      });
      $scope.$watch($scope.showDrawProvider, (showDrawValue, oldValue) => {
        if (showDrawValue == oldValue) {
          return;
        }
        if (!showDrawValue) {
          if (drawControl._map) {
            map.removeControl(drawControl);
          }
          if (drawControlEditOnly._map) {
            map.removeControl(drawControlEditOnly);
          }
          if (drawnItems && drawnItems._map) {
            map.removeLayer(drawnItems);
          }
          return;
        }
        if (showDrawValue) {
          initializeDraw();
        }
      });


      function fieldRecordClustering(overlay){
        var clusters = new Leaflet.MarkerClusterGroup();
        _.forEach(overlay._layers,l =>{
          clusters.addLayer(l);
        })
        return  clusters;
      }

      function initializeMapCenterLayer() {
        if (map && mapCenterMarker) {
          mapCenterLayer = new Leaflet.marker(map.getCenter(), _.defaults(mapCenterMarker, { clickable: false }));
          mapCenterLayer.addTo(map);
        }
      }

      function initializeDraw() {

        var layers = $scope.layersProvider();
        var drawnable = layers.find(function(layer) {
          return layer.config && layer.config.drawnable;
        });
        if (drawnable) {
          drawnItems = Leaflet.geoJSON(drawnable);
          map.addLayer(drawnItems);
        } else {
          drawnItems = new Leaflet.FeatureGroup();
          map.addLayer(drawnItems);
        }

        drawControl = new Leaflet.Control.Draw({
          edit: {
            featureGroup: drawnItems,
            poly: {
              allowIntersection: false,
            },
          },
          draw: {
            marker: false,
            circle: false,
            circlemarker: false,
            polygon: {
              allowIntersection: false,
            },
          },
        });
        drawControlEditOnly = new Leaflet.Control.Draw({
          edit: {
            featureGroup: drawnItems,
          },
          draw: false,
        });
        map.addControl(drawnable ? drawControlEditOnly : drawControl);
      }

      function isMapInitialized() {
        return mapInitialized && layersInitialized && imagerySetsInitialized;
      }

      let nbUpdateViewByPosition = 0;
      function updateViewByPosition() {
        if (!isMapInitialized()) {
          return;
        }
        if (++nbUpdateViewByPosition > 1) {
          return;
        }

        if (initialZoom && initialCenter) {
          // On positionne le zoom et le centre de la carte
          map.setView(initialCenter, initialZoom);
        } else if (fitBounds) {
          // On cadre sur les couches
          if (fitBounds.getNorth() === fitBounds.getSouth() && fitBounds.getEast() === fitBounds.getWest()) {
            map.setView(fitBounds.getNorthEast(), 16);
          } else {
            map.fitBounds(fitBounds);
          }
        }
      }

      function updateViewByBounds() {
        if (!isMapInitialized()) {
          return;
        }
        if (fitBounds) {
          // On cadre sur les couches
          if (fitBounds.getNorth() === fitBounds.getSouth() && fitBounds.getEast() === fitBounds.getWest()) {
            map.setView(fitBounds.getNorthEast(), 16);
          } else {
            map.fitBounds(fitBounds);
          }
        } else if (initialZoom && initialCenter) {
          // On positionne le zoom et le centre de la carte
          map.setView(initialCenter, initialZoom);
        }
      }

      function getStudyLayers() {
        return $q(function(resolve) {
          var tileLayerPromises = _.map(imagerySets, (imagerySet) => {
            return LayerProvider.provideLayer(imagerySet, $scope.databasePrefix);
          });

          $q.all(tileLayerPromises).then((tileLayers) => {
            resolve(tileLayers);
          });
        });
      }

      function getAdditionnalLayers() {
        return $q(function(resolve) {
          if (IS_CORDOVA && _.get(navigator, 'connection.type') == 'none') {
            return resolve([]);
          }
          setTimeout(function() {
            MapBackgroundsService.load().then((layers) => {
              let customLayers = _.get(SettingsStore.get(), 'settings.custom_map_layers', '');
              customLayers = customLayers.split(/\r?\n/g).map((row) => {
                const i = row.indexOf(';');
                if (i < 0) {
                  return;
                }
                return {
                  label: _.trim(row.slice(0, i)),
                  url: _.trim(row.slice(i + 1)),
                };
              });

              const result = {};
              layers
                .concat(customLayers)
                .filter((layer) => {
                  return layer && layer.label && layer.url;
                })
                .forEach((layer) => {
                  result[layer.label] = Leaflet.tileLayer(layer.url);
                });
              resolve(result);
            });
          }, 250);
        });
      }

      function addLayersToMap() {
        if (!isMapInitialized()) {
          return;
        }
        doNotify = false;
        // force updateview() avant d'ajouter les couches pour avoir les bounds des couches
        updateViewByPosition();
        // Réinitialisation des couches
        map.eachLayer((layer) => {
          const locateControlLayers = _.get(locateControl, '_layer._layers');
          if (
            (locateControl && layer === locateControl._layer) ||
            (mapCenterLayer && layer === mapCenterLayer) ||
            (drawnItems && layer === drawnItems) ||
            (locateControlLayers && locateControlLayers[layer._leaflet_id])
          ) {
            return;
          }
          map.removeLayer(layer);
        });
        // Lors de la mise à jour des layers sur leaflet, le rafraichissement de la
        // configuration (suppression puis ajout des couches) de la map
        // entraîne par moments la perte des couches SVG (couche métier).
        // On décale l'ajout des couches dans un setTimeout pour que Leaflet
        // ait le temps de tout gérer
        setTimeout(function() {
          _.forEach(visibleOverlays, (overlay) => {
            if (overlay.options && overlay.options.drawnable && $scope.showDrawProvider()) {
              return;
            }
            overlay.addTo(map);
          });
          updateViewByPosition();

          $q.all([getStudyLayers(), getAdditionnalLayers()]).then((layers) => {
            const studyLayers = layers[0];
            const additionnalLayers = layers[1];
            var baseLayers = _.reduce(
              studyLayers,
              (acc, tileLayer) => {
                return _.merge(acc, { [$filter('imagerySet')(tileLayer.imagerySet)]: tileLayer.layer });
              },
              {}
            );
            const defaultLayerName = $scope.defaultLayer;
            let defaultLayer = additionnalLayers[defaultLayerName];
            if (!defaultLayer) {
              try {
                defaultLayer = _.find(studyLayers, { imagerySet: defaultLayerName }).layer;
              } catch (error) {
                defaultLayer = studyLayers[0].layer;
              }
            }
            defaultLayer.addTo(map);

            // Réinitialisation du contrôles des layers
            if (layersControl) {
              map.removeControl(layersControl);
            }
            layersControl = Leaflet.control.layers(Object.assign({}, baseLayers, additionnalLayers), overlays);
            layersControl.addTo(map);
            doNotify = true;
          });
        }, 100);
      }

      var removeWatcher = $scope.$watch(
        () => jqElement[0].offsetParent,
        () => {
          if (jqElement[0].offsetParent === null) {
            return;
          }

          map = Leaflet.map(jqElement[0], { maxZoom: 18 });

          // On notifie la position au chargement de la carte en cas de sauvegarde par un composant parent
          map.on('load', function(e) {
            $scope.onMoveFn() && $scope.onMoveFn()(map.getCenter(), map.getZoom());
          });

          // Initialisation des panes : mbtiles / kml / data
          map.createPane('mbtiles');
          map.createPane('kml');
          map.createPane('data');

          Leaflet.control.scale({ imperial: false }).addTo(map);

          if (!$scope.noLocateControlFn()) {
            locateControl = Leaflet.control.locate(
              _.defaults(
                {
                  position: 'topright',
                  follow: false,
                  remainActive: true,
                  keepCurrentZoomLevel: true,
                  icon: 'mdi mdi-crosshairs',
                  iconLoading: 'mdi mdi-sync',
                  strings: {
                    title: 'Suivre ma position',
                    metersUnit: 'mètres',
                    popup: "L'imprécision du GPS est de {distance} {unit}",
                    outsideMapBoundsMsg: "La localisation est en dehors de l'aire d'étude",
                  },
                  locateOptions: {
                    enableHighAccuracy: true,
                  },
                  layer: new L.LayerGroup(),
                },
                LAYERS_CONFIG.GPS
              )
            );
            locateControl.addTo(map);
            if (IS_CORDOVA) {
              map.on('locateactivate', () => {
                SettingsOppService.setShuSettings('isLocateControlActivate', 1);
              });
              map.on('locatedeactivate', () => {
                SettingsOppService.setShuSettings('isLocateControlActivate', 0);
              });
              SettingsOppService.getShuSettings('isLocateControlActivate', 0).then((value) => {
                if (Number(value)) {
                  locateControl.start();
                }
              });
            }
          }

          var BackToCenterCtrl = Leaflet.Control.extend({
            options: {
              position: 'topright',
            },
            onAdd: (map) => {
              var container = Leaflet.DomUtil.create(
                'div',
                'map-container__custom-control leaflet-bar leaflet-control'
              );
              var centerLink = Leaflet.DomUtil.create('a', 'leaflet-bar-part leaflet-bar-part-single', container);
              centerLink.title = "Centrer sur l'aire d'étude";
              centerLink.href = '#';
              var centerSpan = Leaflet.DomUtil.create('span', 'mdi mdi-image-filter-center-focus', centerLink);

              Leaflet.DomEvent.on(centerLink, 'click', Leaflet.DomEvent.stopPropagation)
                .on(centerLink, 'click', Leaflet.DomEvent.preventDefault)
                .on(centerLink, 'click', updateViewByBounds, this)
                .on(centerLink, 'dblclick', Leaflet.DomEvent.stopPropagation);

              return container;
            },
          });
          map.addControl(new BackToCenterCtrl());

          var onLayerChangedFn = $scope.onLayerChangedFn();
          if (onLayerChangedFn) {
            var onLayerChanged = (layerEvent) => {
              onLayerChangedFn(layerEvent.layer.options.imagerySet || layerEvent.name);
            };
            map.on('baselayerchange', onLayerChanged);
            $scope.$on('$destroy', () => {
              map.off('baselayerchange', onLayerChanged);
            });
          }
          var onLocalLayerChangedFn = $scope.onLocalLayerChangedFn();
          if (onLocalLayerChangedFn) {
            var onLocalLayerChanged = (layerEvent) => {
              _.get(layerEvent, 'layer.options.controlEventHooks.' + layerEvent.type) &&
                layerEvent.layer.options.controlEventHooks[layerEvent.type](layerEvent);
              if (doNotify && layerEvent.layer.options.id) {
                onLocalLayerChangedFn(layerEvent.layer.options.id);
              }
            };
            map.on('overlayadd', onLocalLayerChanged);
            $scope.$on('$destroy', () => {
              map.off('overlayadd', onLocalLayerChanged);
            });
            map.on('overlayremove', onLocalLayerChanged);
            $scope.$on('$destroy', () => {
              map.off('overlayremove', onLocalLayerChanged);
            });
          }
          mapInitialized = true;
          addLayersToMap();
          map.on('move', function(e) {
            $scope.onMoveFn() && $scope.onMoveFn()(map.getCenter(), map.getZoom());
            if (mapCenterLayer) {
              mapCenterLayer.setLatLng(map.getCenter());
            }
          });

          map.on('draw:created', function(event) {
            var layer = event.layer;
            drawnItems.addLayer(layer);
            var geoJson = drawnItems.toGeoJSON();
            map.removeControl(drawControl);
            map.addControl(drawControlEditOnly);
            $scope.onDrawCreatedFn() && $scope.onDrawCreatedFn()(geoJson);
          });

          map.on('draw:edited', function(event) {
            var geoJson = drawnItems.toGeoJSON();
            $scope.onDrawEditedFn() && $scope.onDrawEditedFn()(geoJson);
          });

          map.on('draw:deleted', function(event) {
            map.addControl(drawControl);
            map.removeControl(drawControlEditOnly);
            $scope.onDrawDeletedFn() && $scope.onDrawDeletedFn()();
          });

          removeWatcher();

          initializeMapCenterLayer();

          const onMapReadyFn = $scope.onMapReadyFn();
          if (onMapReadyFn) {
            onMapReadyFn({
              map,
              getOverlayByName(name) {
                for (const key in overlays) {
                  const overlay = overlays[key];
                  if (_.get(overlay, 'options.name') == name) {
                    return overlay;
                  }
                }
              },
            });
          }
        }
      );
    },
  };
}
