require('./field-record-description-dialog.scss');

export default FieldRecordDescriptionDialogDirective;

// @ngInject
function FieldRecordDescriptionDialogDirective() {
  return {
    restrict: 'E',
    scope: {
      fieldRecordDescriptionStateProvider: '&fieldRecordDescriptionState',
      canEditStudyProvider: '&canEditStudy',
      dialogId: '@',
      onSubmitFn: '&onSubmit'
    },
    controller: FieldRecordDescriptionDialogController,
    controllerAs: 'fieldRecordDescriptionDialogCtrl',
    bindToController: true,
    template: require('./field-record-description-dialog.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDescriptionDialogController($scope) {
  var fieldRecordDescriptionDialogCtrl = this;

  fieldRecordDescriptionDialogCtrl.state = {};
  fieldRecordDescriptionDialogCtrl.description = null;

  $scope.$watch(fieldRecordDescriptionDialogCtrl.canEditStudyProvider,
    (canEditStudy) => fieldRecordDescriptionDialogCtrl.canEditStudy = canEditStudy);

  $scope.$watch(fieldRecordDescriptionDialogCtrl.fieldRecordDescriptionStateProvider, onReceiveNewDescription);

  fieldRecordDescriptionDialogCtrl.onSubmit = onSubmit;

  function onSubmit() {
    if (!fieldRecordDescriptionDialogCtrl.canEdit) {
      return;
    }

    fieldRecordDescriptionDialogCtrl.onSubmitFn() &&
      fieldRecordDescriptionDialogCtrl.onSubmitFn()(fieldRecordDescriptionDialogCtrl.state.fieldRecordDescription.id,
        fieldRecordDescriptionDialogCtrl.description);
  }

  function onReceiveNewDescription(descriptionState) {
    fieldRecordDescriptionDialogCtrl.state = descriptionState;

    var state = fieldRecordDescriptionDialogCtrl.state;

    if (state) {
      fieldRecordDescriptionDialogCtrl.loaded = state.loaded;
      fieldRecordDescriptionDialogCtrl.loading = state.loading;
      fieldRecordDescriptionDialogCtrl.processing = state.processing;
    } else {
      fieldRecordDescriptionDialogCtrl.loaded = false;
      fieldRecordDescriptionDialogCtrl.loading = false;
      fieldRecordDescriptionDialogCtrl.processing = false;
    }

    if (state.processing) {
      return;
    }
    if (state && state.loaded && state.fieldRecordDescription) {
      fieldRecordDescriptionDialogCtrl.canEdit = state.fieldRecordDescription.canEdit && fieldRecordDescriptionDialogCtrl.canEditStudy;
      fieldRecordDescriptionDialogCtrl.description = fieldRecordDescriptionDialogCtrl.state.fieldRecordDescription.description;
    } else {
      fieldRecordDescriptionDialogCtrl.canEdit = false;
      fieldRecordDescriptionDialogCtrl.description = null;
    }
  }
}
